import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { studentActions, teacherActions } from "../../_actions";
import { curriculumConstants } from "../../_constants";
import { useParams } from "react-router-dom";
import { SelectDate } from "../../_components/Calendar";

import moment from "moment";

function PopUpFilterV2(props) {
  let {
    width,
    onClickNo,
    isFilterByKeyWord,
    isFilterByRangeDate,
    isFilterBySkill,
    isFilterByGrade,
    isFilterByLevel,
    isFilterByFormat,
    onSubmitFilter,
    paramFilter,
  } = props;

  const [filters, setFilters] = useState(paramFilter);
  const grades = useSelector((state) => state.grades);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isFilterByGrade) {
      dispatch(studentActions.getAllGrade());
    }
  }, [isFilterByGrade]);

  function handleChange(e) {
    const { name, value } = e.target;
    let tempValue = filters[name];
    const index = tempValue.indexOf(value);
    if (index === -1) {
      tempValue = [...tempValue, value];
    } else {
      tempValue = tempValue.filter((item) => item !== value);
    }
    switch (name) {
      case "grade_id":
        setFilters((filters) => ({ ...filters, grade_id: tempValue }));
        break;
      case "level":
        setFilters((filters) => ({ ...filters, level: tempValue }));
        break;
      case "skill":
        setFilters((filters) => ({ ...filters, skill: tempValue }));
        break;
      case "type":
        setFilters((filters) => ({ ...filters, type: tempValue }));
        break;
    }
  }

  function handleChangeTopic(e) {
    const { value } = e.target;
    setFilters((filters) => ({ ...filters, topic: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    onSubmitFilter(filters);
    onClickNo && onClickNo();
  }

  function resetFilter() {
    let newFilters = {
      topic: "",
      skill: [],
      level: [],
      grade_id: [],
      type: [],
      start_time: "",
      end_time: "",
    };
    setFilters(newFilters);
  }

  function validateParam() {
    if (isFilterByKeyWord && filters.topic) {
      return true;
    } else if (isFilterByRangeDate && filters.start_time && filters.end_time) {
      return true;
    } else if (isFilterBySkill && filters.skill.length) {
      return true;
    } else if (isFilterByLevel && filters.level.length) {
      return true;
    } else if (isFilterByGrade && filters.grade_id.length) {
      return true;
    } else if (isFilterByFormat && filters.type.length) {
      return true;
    }
    return false;
  }

  function isDisableSubmit() {
    if (
      filters.start_time &&
      filters.end_time &&
      filters.start_time > filters.end_time
    ) {
      return true;
    }
    return false;
  }

  let isEndTimeLessThanFromTime =
    filters.start_time &&
    filters.end_time &&
    filters.start_time > filters.end_time;

  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      <div
        className={
          "uk-modal-dialog flex-column uk-modal-body uk-margin-auto-vertical"
        }
        style={{ width: width }}
      >
        <div className="flex-m filter-popup-title">
          <button
            className={
              validateParam()
                ? "btn-disable-status"
                : "btn-line-blue-status-all"
            }
          >
            Tất cả
          </button>
          <img
            height={18}
            src="/assets/images/icon/ico_close_popup.png"
            alt="ico_close_popup"
            className="ico_close_popup __icon_close_filter"
            onClick={onClickNo}
          />
        </div>
        <div
          className="popup-filter-option scrollbar-custom"
          style={{ flex: 1 }}
        >
          {isFilterByKeyWord && (
            <Fragment>
              <span className="title-sp">Lọc theo từ khóa</span>
              <input
                type="text"
                placeholder="Nhập từ khóa cần tìm"
                className="input-search"
                onChange={handleChangeTopic}
                name="topic"
                value={filters.topic}
              />
            </Fragment>
          )}
          <div className={"pad-020"}>
            {isFilterByRangeDate && (
              <div className="flex-m class-slect-time set-180 space-around-content popup-filter-select-date">
                <div className="text-center">
                  <div
                    className={
                      "sunE-input-group slect-time" +
                      (filters.start_time > filters.end_time ? " err" : "")
                    }
                  >
                    <label>
                      Từ ngày<span className="red"></span>
                    </label>
                    <SelectDate
                      showTimeSelect={false}
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy"
                      selected={
                        filters.start_time !== ""
                          ? new Date(filters.start_time)
                          : filters.start_time
                      }
                      onChange={(start_time) =>
                        setFilters({
                          ...filters,
                          start_time: moment(start_time).format("YYYY-MM-DD"),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="text-center">
                  <div
                    className={
                      "sunE-input-group slect-time" +
                      (filters.start_time > filters.end_time ? " err" : "")
                    }
                  >
                    <label>
                      Đến ngày<span className="red"></span>
                    </label>
                    <SelectDate
                      showTimeSelect={false}
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy"
                      selected={
                        filters.end_time !== ""
                          ? new Date(filters.end_time)
                          : filters.end_time
                      }
                      onChange={(end_time) =>
                        setFilters({
                          ...filters,
                          end_time: moment(end_time).format("YYYY-MM-DD"),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                color: "#e22028",
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              {isEndTimeLessThanFromTime &&
                "Ngày kết thúc không được nhỏ hơn ngày bắt đầu"}
            </div>
            {/* <div className="box-input">
                            <div className="uk-grid">
                                <div className="chk-gr uk-width-1-2">
                                    <span className="chk-custom-gr wh20">
                                        <input type="checkbox" id="Speaking" name="skill" checked={filters.skill.indexOf("Speaking") !== -1 ? true : false} onChange={handleChange} value="Speaking" />
                                        <label htmlFor="Speaking"> Speaking</label>
                                    </span>
                                </div>
                                <div className="chk-gr uk-width-1-2">
                                    <span className="chk-custom-gr wh20">
                                        <input type="checkbox" id="Writing" name="skill" checked={filters.skill.indexOf("Writing") !== -1 ? true : false} onChange={handleChange} value="Writing" />
                                        <label htmlFor="Writing"> Writing</label>
                                    </span>
                                </div>
                                <div className="chk-gr uk-width-1-2">
                                    <span className="chk-custom-gr wh20">
                                        <input type="checkbox" id="Project" name="skill" checked={filters.skill.indexOf("Project") !== -1 ? true : false} onChange={handleChange} value="Project" />
                                        <label htmlFor="Project"> Project</label>
                                    </span>
                                </div>
                            </div>
                        </div> */}
            {isFilterBySkill && (
              <div className="box-input">
                <span className={"title-sp"}>Lọc theo kỹ năng</span>
                <div className="uk-grid">
                  <div className="chk-gr uk-width-1-2">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        id="Pronunciation"
                        name="skill"
                        checked={
                          filters.skill.indexOf("Pronunciation") !== -1
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        value="Pronunciation"
                      />
                      <label htmlFor="Pronunciation"> Pronunciation</label>
                    </span>
                  </div>
                  <div className="chk-gr uk-width-1-2">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        id="Listening"
                        name="skill"
                        checked={
                          filters.skill.indexOf("Listening") !== -1
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        value="Listening"
                      />
                      <label htmlFor="Listening"> Listening</label>
                    </span>
                  </div>
                  <div className="chk-gr uk-width-1-2">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        id="Vocabulary"
                        name="skill"
                        checked={
                          filters.skill.indexOf("Vocabulary") !== -1
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        value="Vocabulary"
                      />
                      <label htmlFor="Vocabulary"> Vocabulary</label>
                    </span>
                  </div>
                  <div className="chk-gr uk-width-1-2">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        id="Speaking"
                        name="skill"
                        checked={
                          filters.skill.indexOf("Speaking") !== -1
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        value="Speaking"
                      />
                      <label htmlFor="Speaking"> Speaking</label>
                    </span>
                  </div>
                  <div className="chk-gr uk-width-1-2">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        id="Grammar"
                        name="skill"
                        checked={
                          filters.skill.indexOf("Grammar") !== -1 ? true : false
                        }
                        onChange={handleChange}
                        value="Grammar"
                      />
                      <label htmlFor="Grammar"> Grammar</label>
                    </span>
                  </div>
                  <div className="chk-gr uk-width-1-2">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        id="Writing"
                        name="skill"
                        checked={
                          filters.skill.indexOf("Writing") !== -1 ? true : false
                        }
                        onChange={handleChange}
                        value="Writing"
                      />
                      <label htmlFor="Writing"> Writing</label>
                    </span>
                  </div>
                  <div className="chk-gr uk-width-1-2">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        id="Reading"
                        name="skill"
                        checked={
                          filters.skill.indexOf("Reading") !== -1 ? true : false
                        }
                        onChange={handleChange}
                        value="Reading"
                      />
                      <label htmlFor="Reading"> Reading</label>
                    </span>
                  </div>
                  <div className="chk-gr uk-width-1-2">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        id="Project"
                        name="skill"
                        checked={
                          filters.skill.indexOf("Project") !== -1 ? true : false
                        }
                        onChange={handleChange}
                        value="Project"
                      />
                      <label htmlFor="Project"> Project</label>
                    </span>
                  </div>
                  <div className="chk-gr uk-width-1-2">
                    <span className="chk-custom-gr wh20">
                      <input
                        type="checkbox"
                        id="Test"
                        name="skill"
                        checked={
                          filters.skill.indexOf("Test") !== -1 ? true : false
                        }
                        onChange={handleChange}
                        value="Test"
                      />
                      <label htmlFor="Test"> Test</label>
                    </span>
                  </div>
                </div>
              </div>
            )}
            {isFilterByGrade && (
              <Fragment>
                <span className="title-sp">Lọc theo khối lớp</span>
                <div className="box-input">
                  <div className="uk-grid">
                    {grades.map((item, i) => {
                      return (
                        <div className="chk-gr uk-width-1-2" key={i}>
                          <span className="chk-custom-gr wh20">
                            <input
                              type="checkbox"
                              name="grade_id"
                              id={"k" + i}
                              onChange={handleChange}
                              value={item.id}
                              checked={
                                filters.grade_id.indexOf(item.id) !== -1
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor={"k" + i}> {item.name}</label>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Fragment>
            )}
            {isFilterByLevel && (
              <Fragment>
                <span className="title-sp">Lọc theo độ khó</span>
                <div className="box-input">
                  <div className="uk-grid">
                    <div className="chk-gr uk-width-1-2">
                      <span className="chk-custom-gr wh20">
                        <input
                          type="checkbox"
                          id="Easy"
                          name="level"
                          checked={
                            filters.level.indexOf("Easy") !== -1 ? true : false
                          }
                          onChange={handleChange}
                          value="Easy"
                        />
                        <label htmlFor="Easy"> Easy</label>
                      </span>
                    </div>
                    <div className="chk-gr uk-width-1-2">
                      <span className="chk-custom-gr wh20">
                        <input
                          type="checkbox"
                          id="Hard"
                          name="level"
                          checked={
                            filters.level.indexOf("Hard") !== -1 ? true : false
                          }
                          onChange={handleChange}
                          value="Hard"
                        />
                        <label htmlFor="Hard"> Hard</label>
                      </span>
                    </div>
                    <div className="chk-gr uk-width-1-2">
                      <span className="chk-custom-gr wh20">
                        <input
                          type="checkbox"
                          id="Normal"
                          name="level"
                          checked={
                            filters.level.indexOf("Normal") !== -1
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          value="Normal"
                        />
                        <label htmlFor="Normal"> Medium</label>
                      </span>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            {isFilterByFormat && (
              <Fragment>
                <span className="title-sp">Lọc theo định dạng</span>
                <div className="box-input">
                  <div className="uk-grid">
                    <div className="chk-gr uk-width-1-2">
                      <span className="chk-custom-gr wh20">
                        <input
                          type="checkbox"
                          id="Video"
                          name="type"
                          checked={
                            filters.type.indexOf("Video") !== -1 ? true : false
                          }
                          onChange={handleChange}
                          value="Video"
                        />
                        <label htmlFor="Video"> Video</label>
                      </span>
                    </div>
                    <div className="chk-gr uk-width-1-2">
                      <span className="chk-custom-gr wh20">
                        <input
                          type="checkbox"
                          id="audio"
                          name="type"
                          checked={
                            filters.type.indexOf("audio") !== -1 ? true : false
                          }
                          onChange={handleChange}
                          value="audio"
                        />
                        <label htmlFor="audio"> Âm thanh</label>
                      </span>
                    </div>
                    <div className="chk-gr uk-width-1-2">
                      <span className="chk-custom-gr wh20">
                        <input
                          type="checkbox"
                          id="Text"
                          name="type"
                          checked={
                            filters.type.indexOf("writing") !== -1
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          value="writing"
                        />
                        <label htmlFor="Text"> Văn bản</label>
                      </span>
                    </div>
                    <div className="chk-gr uk-width-1-2">
                      <span className="chk-custom-gr wh20">
                        <input
                          type="checkbox"
                          id="img"
                          name="type"
                          checked={
                            filters.type.indexOf("img") !== -1 ? true : false
                          }
                          onChange={handleChange}
                          value="img"
                        />
                        <label htmlFor="img"> Hình ảnh</label>
                      </span>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <div
          className="flex-m form-sunE-button pad-020"
          style={{
            margin: "10 0 0",
          }}
        >
          <button
            className={
              "btn-custom-width width-100-percent " +
              (!validateParam()
                ? "btn-line-blue border-transparent btn-disable"
                : "btn-default")
            }
            onClick={resetFilter}
          >
            {"Xóa bộ lọc"}
          </button>
          <button
            className={
              "btn-line-blue width-100-percent btn-custom-width " +
              (isDisableSubmit()
                ? "border-transparent btn-disable"
                : "btn-line-blue")
            }
            onClick={handleSubmit}
          >
            Áp dụng
          </button>
        </div>
      </div>
    </div>
  );
}

export { PopUpFilterV2 };
