import React, { Fragment } from "react";
import "./style.css";

function PopUpResentEmail(props) {
  let {
    message,
    onClickNo,
    labelNo,
    width,
    hideButtonNo,
    styleGroupBtn,
    title,
    msgResentEmail,
    handleResentEmail,
  } = props;
  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      <div
        className="uk-modal-dialog uk-modal-dialog-resent uk-modal-body uk-margin-auto-vertical"
        style={{ width: width }}
      >
        <div style={{ textAlign: "center", marginBottom: 8 }}>
          <strong>{title}</strong>
        </div>
        {typeof message === "string" && (
          <div className="text-center fz-11-25">{message}</div>
        )}
        <div
          onClick={handleResentEmail}
          className="font_news_bold pointer_cursor  fz-11-25 mg-top-1 text-align-center text_underline"
        >
          {msgResentEmail}
        </div>
        <div
          className="flex-m form-sunE-button flex-center"
          style={styleGroupBtn}
        >
          {!hideButtonNo && (
            <button
              className={
                "col-fixed-50 btn-line-blue btn_text_popup fz-11 text-align-center"
              }
              onClick={onClickNo}
            >
              {labelNo ? labelNo : "Đóng"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export { PopUpResentEmail };
