import React, { Fragment } from "react";
import "./style.css";

function PopUpYesNo(props) {
  let {
    message,
    onClickYes,
    onClickNo,
    labelYes,
    labelNo,
    width,
    btnCloseBlue,
    hideButtonNo,
    hideButtonYes,
    styleGroupBtn,
    title,
    customWidthBtn,
  } = props;
  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal modal_not_scroll uk-flex uk-open commondPage"
      uk-modal=""
    >
      <div
        className={`uk-modal-dialog uk-modal-dialog-yesno uk-modal-body uk-margin-auto-vertical ${customWidthBtn}`}
        style={{ width: width }}
      >
        <div style={{ textAlign: "center", marginBottom: 8 }}>
          <strong>{title}</strong>
        </div>
        {typeof message === "string" && (
          <p className="text-center">{message}</p>
        )}
        {typeof message === "object"
          ? message[0]?.strongText
            ? message.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <p className="text-center">
                      {item?.msg_1}{" "}
                      <span className="bold">{item?.strongText}</span>{" "}
                      {item?.msg_2}
                    </p>
                  </Fragment>
                );
              })
            : message.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <p className="text-center">{item}</p>
                  </Fragment>
                );
              })
          : null}
        <div className="flex-m form-sunE-button flex-jus-around" style={styleGroupBtn}>
          {!hideButtonNo && (
            <button
              className={
                "btn-default width-100-percent mr-10 btn-action-popup" +
                (btnCloseBlue ? " btn-line-blue" : "")
              }
              onClick={onClickNo}
            >
              {labelNo ? labelNo : "Đóng"}
            </button>
          )}
          {!hideButtonYes && (
            <button
              className="btn-line-blue width-100-percent btn-action-popup"
              onClick={onClickYes}
            >
              {labelYes ? labelYes : "Có"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export { PopUpYesNo };
