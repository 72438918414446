import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { scheduleActions } from './../../_actions';
import { Alert } from './../../_components/Alert';
import { scheduleConstants } from './../../_constants';

import './style.css';

function PopUpResetTimeTable(props) {
    let { onClose, width } = props;
    const dispatch = useDispatch();
    const alert = useSelector(state => state.alert);

    function resetTimeTable() {
        onClose()
        dispatch(scheduleActions.resetTimeTable());
    }

    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ width: width }}>
                {alert.message && alert.screen === scheduleConstants.SCREEN_RESET_TIME_TABLE && <Alert alert={alert} notShowComplete={true} />}
                <p className="text-center popup-title font-strong bold">Bạn có chắc chắn muốn đặt lại <br />thời khóa biểu?</p>
                <p className="text-center popup-title">Đặt lại thời khóa biểu sẽ xóa <br /> toàn bộ thông tin bạn đã tạo!</p>
                <div className="flex-m btn-popup-gr">
                    <button className="btn-default wd-c mr-10" onClick={() => onClose()}>Huỷ</button>
                    <button className="btn-bg-blue btn-default wd-c" onClick={resetTimeTable}>Đặt lại</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpResetTimeTable };