import { alertConstants } from './../_constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(alert) {
    return { type: alertConstants.SUCCESS, alert };
}

function error(alert) {
    return { type: alertConstants.ERROR, alert };
}

function clear() {
    return { type: alertConstants.CLEAR };
}