import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertConstants, userConstants } from "./../../_constants";

import { HeaderCloud, HeaderCurve } from "../../_components/Header";
import { alertActions, userActions } from "../../_actions";
import { Alert } from "../../_components/Alert";
import { LinkBack } from "../../_components/Link";
import { isEmail } from "../../_helpers/validateEmail";
import InputText from "../../_components/Auth/InputText";
import { Link } from "react-router-dom";

function ForgotPasswordPage() {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  useEffect(() => {
    if (alert.type === alertConstants.SUCCESS) {
      setResetSuccess(true);
    }
  }, [alert]);

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (validateParam()) {
      dispatch(userActions.forgotPassword(email));
    }
  }

  function validateParam() {
    return isEmail(email);
  }

  const changeEmail = (value) => {
    if (
      alert.message &&
      alert.screen === userConstants.SCREEN_FORGOT_PASSWORD
    ) {
      dispatch(alertActions.clear());
    }
    if (emailError) {
      if (isEmail(value)) {
        setEmailError("");
      }
    }
    setEmail(value.trim());
  };

  const onBlurEmail = () => {
    if (email && !emailError && !isEmail(email)) {
      setEmailError("Định dạng email không đúng");
    }
  };

  const renderEmailIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24"><path fill="#00B9B7" fill-rule="evenodd" d="M12 1.25a4.75 4.75 0 1 0 0 9.5a4.75 4.75 0 0 0 0-9.5M8.75 6a3.25 3.25 0 1 1 6.5 0a3.25 3.25 0 0 1-6.5 0M12 12.25c-2.313 0-4.445.526-6.024 1.414C4.42 14.54 3.25 15.866 3.25 17.5v.102c-.001 1.162-.002 2.62 1.277 3.662c.629.512 1.51.877 2.7 1.117c1.192.242 2.747.369 4.773.369s3.58-.127 4.774-.369c1.19-.24 2.07-.605 2.7-1.117c1.279-1.042 1.277-2.5 1.276-3.662V17.5c0-1.634-1.17-2.96-2.725-3.836c-1.58-.888-3.711-1.414-6.025-1.414M4.75 17.5c0-.851.622-1.775 1.961-2.528c1.316-.74 3.184-1.222 5.29-1.222c2.104 0 3.972.482 5.288 1.222c1.34.753 1.961 1.677 1.961 2.528c0 1.308-.04 2.044-.724 2.6c-.37.302-.99.597-2.05.811c-1.057.214-2.502.339-4.476.339c-1.974 0-3.42-.125-4.476-.339c-1.06-.214-1.68-.509-2.05-.81c-.684-.557-.724-1.293-.724-2.601" clip-rule="evenodd"/></svg>
    );
  };

  return (
    <div className="bg-login rel">
      <HeaderCurve></HeaderCurve>
      <div className="sunE-form-container login-form-container">
        {!resetSuccess ? (
          <>
            <div className="sunE-custom-form login-form-container_content responsive_small_screen_margin_login">
              <h2 className="title text-center">ĐẶT LẠI MẬT KHẨU</h2>
              <h3
                className="text-center subtitle"
                style={{ marginBottom: "44px", fontSize: "18px" }}
              >
                Bạn vui lòng điền username sử dụng khi tạo tài khoản để lấy lại mật
                khẩu
              </h3>
              {alert.message &&
                alert.screen === userConstants.SCREEN_FORGOT_PASSWORD && (
                  <Alert alert={alert} />
                )}
              <form
                className="sunE-login-app"
                onSubmit={handleSubmit}
                noValidate={true}
              >
                <InputText
                  value={email}
                  setValue={changeEmail}
                  type="text"
                  name="email"
                  placeholder="Nhập username"
                  required={true}
                  renderLabelIcon={renderEmailIcon}
                  errorText={emailError}
                  setErrorText={setEmailError}
                  onBlur={onBlurEmail}
                ></InputText>
                <div className="form-sunE-button" style={{ marginTop: "46px" }}>
                  <button
                    style={{
                      width: "210px",
                      height: "50px",
                      borderRadius: "25px",
                      fontSize: "18px",
                    }}
                    className={
                      "btn-line-blue" + (!validateParam() ? " btn-disable" : "")
                    }
                  >
                    Gửi yêu cầu
                  </button>
                </div>
              </form>
            </div>
            <div className="sunE-bottom-form text-center">
              <LinkBack />
            </div>
          </>
        ) : (
          <>
            <div className="sunE-custom-form login-form-container_content responsive_small_screen_margin_login">
              <h2
                className="title text-center"
                style={{ marginBottom: "56px" }}
              >
                ĐẶT LẠI MẬT KHẨU
              </h2>
              <div
                className="text-center subtitle"
                style={{ marginBottom: "36px", fontSize: "18px" }}
              >
                <svg
                  width={85}
                  height={56}
                  viewBox="0 0 85 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M55.0131 53.616H8.74109C7.00334 53.611 5.33821 52.9185 4.10944 51.6897C2.88066 50.4609 2.18812 48.7956 2.18311 47.0579V8.64896C2.18812 6.91121 2.88066 5.24614 4.10944 4.01736C5.33821 2.78859 7.00334 2.09611 8.74109 2.09109H64.5491C66.2869 2.09611 67.952 2.78859 69.1808 4.01736C70.4095 5.24614 71.1021 6.91121 71.1071 8.64896V19.7229"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.54395 7.44295L36.144 23.6031L63.744 7.576"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M75.727 36.334H66.068C61.8198 36.334 58.376 39.7778 58.376 44.026V46.308C58.376 50.5562 61.8198 54 66.068 54H75.727C79.9752 54 83.419 50.5562 83.419 46.308V44.026C83.419 39.7778 79.9752 36.334 75.727 36.334Z"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M70.8972 23.988C73.1266 23.988 75.2647 24.8736 76.8412 26.4501C78.4176 28.0265 79.3032 30.1646 79.3032 32.394V36.288H62.4912V32.394C62.4912 30.1646 63.3768 28.0265 64.9533 26.4501C66.5297 24.8736 68.6678 23.988 70.8972 23.988V23.988Z"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M70.873 47.828V42.257"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <p className="text-center" style={{ fontSize: "18px" }}>
                  Đường dẫn đặt lại mật khẩu đã được gửi đến địa chỉ email{" "}
                  <span style={{ color: "#00B9B7", fontSize: "18px" }}>
                    {email}
                  </span>
                  . Bạn vui lòng kiểm tra hộp thư và làm theo hướng dẫn nhé!
                </p>
              </div>
            </div>
            <div className="form-sunE-button" style={{ marginTop: "105px" }}>
              <Link to="/login">
                <button
                  style={{
                    width: "210px",
                    height: "50px",
                    borderRadius: "25px",
                    fontSize: "18px",
                  }}
                  className={"btn-line-blue"}
                >
                  Đồng ý
                </button>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export { ForgotPasswordPage };
