import React, { useEffect, useState } from "react";

function SelectAsDiv(props) {
  let {
    textDefault,
    data,
    onChangeSelect,
    keySelected,
    ico_url,
    list_style,
    id,
    className,
    hzLine,
    ico_filter,
    position,
    disabled,
    fix_text_cut_chart,
    styleOption,
    styleBox,
    styleItem
  } = props;

  if (!id) {
    id = "id";
  }
  let wrapperRef = null;
  let keyName = props.keyName ? props.keyName : "name";
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", _click_outside);
    return () => {
      document.removeEventListener("mousedown", _click_outside);
    };
  }, []);

  function _click_outside(event) {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setShow(false);
    }
  }

  const _set_wrapper_ref_find = (node) => {
    if (!wrapperRef) {
      wrapperRef = node;
    }
  };

  function closeAndSetValue(key) {
    setShow(false);
    onChangeSelect(key);
  }

  if (keySelected) {
    if (data.find((item) => item[id] === keySelected)) {
      textDefault = data.find((item) => item[id] === keySelected)[keyName];
    }
  }

  return (
    <div
      className={`select rel ${className ? className : ""}`}
      ref={(e) => _set_wrapper_ref_find(e)}
    >
      <div
        style={{ zIndex: 1010, pointerEvents: disabled ? "none" : "", ...styleBox }}
        className={`select-styled one-line ${show ? "active" : ""} ${fix_text_cut_chart  ? "fix_text_cut_chart" : ""} `}
        onClick={() => setShow(!show)}
        id={keySelected}
      >
        {textDefault} {hzLine && <span className="line">|</span>}
      </div>
      <ul
        style={{
          zIndex: 1000,
          ...styleOption,
          display: show ? "block" : "none",
          marginTop: 10,
        }}
        className={"select-options scrollbar-custom " + position}
      >
        {data?.map((item, i) => {
          return (
            <li key={i} onClick={() => closeAndSetValue(item[id])} style={styleItem}>
              {list_style && <span className="circle"></span>}
              {item[keyName]}
            </li>
          );
        })}
      </ul>
      {ico_url && (
        <img
          src={ico_url}
          alt={"ico_icon_select"}
          className="ico_icon_select"
        />
      )}
      {ico_filter && (
        <img src={ico_filter} alt={"ico_filter"} className="ico_filter" />
      )}
    </div>
  );
}

export { SelectAsDiv };
