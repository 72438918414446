import React, { useEffect, useState } from "react";
import Login from "./components/Login";
import { HeaderCurve } from "../../_components/Header";
import { userActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "../../_helpers/validateEmail";
import { configConstants, userConstants } from "../../_constants";
import classNames from "classnames";
import { stepAuthConstants } from "../../_constants/auth";
import {Mydata} from '../../_base/Mydata'

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [pwError, setPwError] = useState("");
  const dispatch = useDispatch();
  const [loginType, setLoginType] = useState({
    type: "default",
    step: "normal_login",
  });
  const authentication = useSelector((state) => state.authentication);
  const [dataStepEmail, setDataStepEmail] = useState({
    email: "",
  });
  const [dataStepRole, setDataStepRole] = useState({
    role: "",
    grade: "",
    schoolType: "",
  });
  const [dataUpdateInfo, setDataUpdateInfo] = useState({
    fullname: "",
    birthday: "",
    gender: "",
    phone: "",
    province: "",
    district: "",
    school: "",
    onRegister: false,
  });
  const getDevice = () => {
    let currentBrowser = "Unknown";
    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      currentBrowser = "Google_Chrome";
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      currentBrowser = "Mozilla_Firefox";
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      currentBrowser = "Internet_Exployer";
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      currentBrowser = "Edge";
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      currentBrowser = "Safari";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      currentBrowser = "Opera";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      currentBrowser = "YaBrowser";
    } else {
      return currentBrowser;
    }
    return currentBrowser;
  };
  const makeToken = () => {
    let ID = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < 12; i++) {
      ID += characters.charAt(Math.floor(Math.random() * 36));
    }
    return ID;
  };
  const [inputs, _setInputs] = useState({
    device_id: makeToken(),
    device_name: getDevice(),
    fcm_token: makeToken(),
  });
  const [submitted, setSubmitted] = useState(false);
  const [cacheTokenSocial, setCacheTokenSocial] = useState({});

  // method---------------------------------------------

  useEffect(() => {
    if (
      authentication.loginType === configConstants.LOGIN_VIA_FACEBOOK ||
      authentication.loginType === configConstants.LOGIN_VIA_GOOLE
    ) {
      if (authentication.is_new_acc) {
        setLoginType({
          type: authentication.loginType,
          step:
            authentication.loginType === configConstants.LOGIN_VIA_FACEBOOK
              ? stepAuthConstants.STEP_CREATE_ACCOUNT
              :
            stepAuthConstants.STEP_ACCOUNT_TYPE,
        });
        dispatch({
          type: userConstants.LOGOUT,
        });
      }
    }
  }, [authentication]);

  useEffect(() => {
    if (dataUpdateInfo.onRegister) {
      onLoginBySocial();
    }
  }, [dataUpdateInfo]);

  useEffect(() => {
    if (loginType.type === "default") {
      resetStateLoginSocial();
    }
  }, [loginType]);

  const onLoginBySocial = () => {
    const data = {
      email: dataStepEmail.email,
      user_role: dataStepRole.role,
      class: dataStepRole.grade,
      school_type: dataStepRole.schoolType,
      grade_id: dataStepRole.grade,
      school: dataUpdateInfo.school,
      birthday: dataUpdateInfo.birthday,
      fullname: dataUpdateInfo.fullname,
      gender: dataUpdateInfo.gender,
      province_alias: dataUpdateInfo.province,
      district_alias: dataUpdateInfo.district,
      phone: dataUpdateInfo.phone,
    };
    
    dispatch(
      userActions.login(
        {
          ...cacheTokenSocial,
          ...data,
        },
        loginType.type
      )
    );
  };

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (validateParam()) {
      const dataLogin = {
        ...inputs,
        username: email,
        password: password,
      };
      dispatch(
        userActions.login(dataLogin, userConstants.LOGIN_TYPE_DEFAULT)
      );
    }
  }

  // function onLoginFacebookSuccess(user) {
  //   console.log(user);
  //   if (user && user._token) {
  //     const data = {
  //       access_token: user._token.accessToken,
  //       organization_id: 1,
  //     };
  //     setCacheTokenSocial(data);
  //     dispatch(userActions.login(data, configConstants.LOGIN_VIA_FACEBOOK));
  //   }
  // }

  function onLoginGoogleSuccess(user) {
    // console.log(user);
    // alert("Waiting Api");
    setDataStepEmail({
      email: user.profileObj?.email ?? "",
    });
    setDataUpdateInfo({
      ...dataUpdateInfo,
      fullname: user.profileObj?.name ?? "",
    });
    const data = {
      id_token: user.tokenId,
      access_token: user.accessToken,
      organization_id: 1,
    };
    setCacheTokenSocial(data);
    dispatch(userActions.login(data, configConstants.LOGIN_VIA_GOOLE));
    dispatch(userConstants.REGISTER_WITH_OTHER_ZALO)
  }

  function onLoginAppleSuccess(response) {
    setDataStepEmail({
      email: response.user?.email ?? "",
    });
    setDataUpdateInfo({
      ...dataUpdateInfo,
      fullname:
        response.user?.name?.firstName ??
        "" + response.user?.name?.lastName ??
        "",
    });
    const data = {
      identityToken: user.authorization.id_token,
      organization_id: 1,
    };
    setCacheTokenSocial(data);
    dispatch(userActions.login(data, configConstants.LOGIN_VIA_APPLE));
  }

  function validateParam() {
    return !!email?.trim() && password.length >= 6 && password.trim() !== ""
      ? true
      : false;
  }

  const changeStepLoginSocial = (step) => {
    setLoginType({
      ...loginType,
      step: step,
    });
  };

  const backStepFromAccountType = () => {
    if (loginType.type === configConstants.LOGIN_VIA_GOOLE) {
      setLoginType({
        type: "default",
        step: "normal_login",
      });
    } 
    // else if (loginType.type === configConstants.LOGIN_VIA_FACEBOOK) {
    //   setLoginType({
    //     ...loginType,
    //     step: stepAuthConstants.STEP_CREATE_ACCOUNT,
    //   });
    // }
  };

  const resetStateLoginSocial = () => {
    setDataStepEmail({
      email: "",
    });
    setDataStepRole({
      role: "",
      grade: "",
      schoolType: "",
    });
    setDataUpdateInfo({
      fullname: "",
      birthday: "",
      gender: "",
      phone: "",
      province: "",
      district: "",
      school: "",
      onRegister: false,
    });
  };

  return (
    <div className="bg-login rel">
      <HeaderCurve></HeaderCurve>
      {loginType.step === "normal_login" ? (
        <>
          <div>
            <Login
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              emailError={emailError}
              setEmailError={setEmailError}
              pwError={pwError}
              setPwError={setPwError}
              handleSubmit={handleSubmit}
              // onLoginFacebookSuccess={onLoginFacebookSuccess}
              onLoginGoogleSuccess={onLoginGoogleSuccess}
              onLoginAppleSuccess={onLoginAppleSuccess}
              validateParam={validateParam}
            ></Login>
          </div>
          {/* <div className="version-app">{Mydata.version}</div> */}
        </>
      ) : null}
    </div>
  );
}

export { LoginPage };
