import { TypeHeaderNewsItem } from "../_constants/headerNews";

// Validate Email Regex
export const validateEmail = (email) => {
  var regex = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/gm;
  return regex.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
  // Regex check format of phone number in vietnam
  const phoneNumberRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

  return phoneNumberRegex.test(phoneNumber);
};

// Validate Number Regex
export const validateNumber = (value) => {
  return value?.replace(/[^0-9]/g, "");
};

// Convert Time Package Fee
export const convertTimePackage = (time) => {
  let resultTime = "";

  let textTime = "";

  switch (true) {
    case time < 30:
      textTime = "ngày";
      resultTime = time;
      break;
    case time >= 30 && time < 365:
      textTime = "tháng";
      resultTime = Math.floor(time / 30);
      break;
    case time >= 365:
      textTime = "năm";
      resultTime = Math.floor(time / 365);
      break;
    default:
  }

  return `(${resultTime} ${textTime})`;
};

// Format Price
export const validatePrice = (value) => {
  let formattedPrice = "";
  let price = parseFloat(value);
  if (price >= 1000) {
    // If it's greater than or equal to 1000, format it with commas
    formattedPrice = price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    // If it's less than 1000, format it with three decimal places
    formattedPrice = price.toFixed(0);
  }

  return formattedPrice;
};

// Format Date
export const validateTime = (timer) => {
  /* eslint-disable no-unsafe-optional-chaining */
  const [date, time] = timer?.split(" ");

  const [year, month, day] = date.split("-");

  return `${day}/ ${month}/ ${year}`;
};

// Check Even
export const handleCheckEven = (number) => {
  return number % 2 == 0;
};

// Round Number to Fixed Specific Number
export const roundNumber = (number, valueRound) => {
  return parseFloat(Number.parseFloat(number).toFixed(valueRound));
};

// Validate positive decimal number 2
export const validateDecimal2Number = (number) => {
  return number?.replace(/^(\\d+(?:[.,]\d{0,2}))?$/, "");
};

// Check Positive Float
export const checkPositiveFloat = (number) => {
  const regex = /^(?:10(\.0{1,2})?|[1-9]\d*(\.\d{1,2})?|0(\.\d{1,2})?)?$/;
  return regex.test(number);
};

// Handle Click Link App Mobile
/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
};

// Has Duplicate Data
export function hasDuplicates(array1, array2) {
  var combinedArray = array1.concat(array2);
  return new Set(combinedArray).size !== combinedArray.length;
}

// func convert title
export const convertTitleAssign = (stand) => {
  switch (stand.toLowerCase()) {
    case "hard":
    case "good":
    case "senior":
      return "Senior";
    case "medium":
    case "normal":
    case "middle":
      return "Middle";
    case "easy":
    case "weak":
    case "junior":
      return "Junior";
    default:
  }
};

// Convert SKill Name
export const vietsubSkillName = (skill) => {
  switch (skill) {
    case "pronunciation":
      return "Phát âm";
    case "vocabulary":
      return "Từ vựng";
    case "grammar":
      return "Ngữ pháp";
    case "reading":
      return "Đọc";
    case "listening":
      return "Nghe";
    case "speaking":
      return "Nói";
    case "writing":
      return "Viết";
    default:
      return "";
  }
};

// Float Array
export const floatObjectToArray = (object) => {
  const array = Object.keys(object).reduce(function (res, curr) {
    return res.concat(object[curr]);
  }, []);

  return array;
};

// Has Domain Store
export const hasDomainStore = () => {
  var currentDomain = window.location.hostname;

  return currentDomain.includes("gkcorp.com.vn");
};

// Convert Header Item Vietsub

export const convertNameHeaderItem = (type) => {
  switch (type) {
    case TypeHeaderNewsItem.INTRODUCTION:
      return "Ứng dụng DẠY và HỌC Tiếng Anh dành cho học sinh THCS | Sunday English";
    case TypeHeaderNewsItem.COURSES:
      return "Trọn bộ Khóa học | Sunday English";
    case TypeHeaderNewsItem.FEE:
    case TypeHeaderNewsItem.TARIFF:
      return "Bảng giá | Sunday English";
    case TypeHeaderNewsItem.PARENT:
      return "Giúp ba mẹ kèm con học Tiếng Anh | Sunday Parent";
    case TypeHeaderNewsItem.TEACHER:
      return "Trợ giảng đắc lực của thầy cô | Sunday Teacher";
    case TypeHeaderNewsItem.MOCK_TEST:
      return "Thi thử và Giải đề miễn phí | Sunday English";
    case "fee_policy":
      return "Hướng dẫn thanh toán | Sunday English";
    case "policy":
      return "Điều khoản và Chính sách dịch vụ | Sunday English";
    case "faq":
      return "Câu hỏi thường gặp | Sunday English";
    case "account_activation":
      return "Hướng dẫn Đăng ký - Đăng nhập - Kích hoạt tài khoản | Sunday English";
    default:
      return "Sunday English - Nâng cao điểm số Tiếng Anh";
  }
};

// Function to remove Vietnamese diacritics
export const removeVietnameseDiacritics = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

// Convert Skill name to Vietnamese
export const convertNameSkillVn = (skill) => {
  switch (skill) {
    case "exam":
      return "Bài kiểm tra";
    case "project":
      return "Bài tập lớn";
    case "mini_test":
      return "Bài kiểm tra";
    case "pronunciation":
      return "Phát âm";
    case "vocabulary":
      return "Từ vựng";
    case "grammar":
      return "Ngữ pháp";
    case "reading":
      return "Đọc";
    case "listening":
      return "Nghe";
    case "speaking":
      return "Nói";
    case "writing":
      return "Viết";
    default:
      return "";
  }
};

// Check equal between 2 arrays
export function arraysAreEqual(arr1, arr2) {
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Check if each object in the arrays is equal based on object properties
  return arr1.every((obj1, index) => {
    const obj2 = arr2[index];
    // Check if the number of keys is equal
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    // Check if all key-value pairs are equal
    return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
  });
}

// Convert &
export function replaceAnd(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] == "string") {
        obj[key] = obj[key].replace(/&/g, "and");
      } else if (typeof obj[key] == "object" && obj[key] !== null) {
        replaceAnd(obj[key]);
      }
    }
  }
  return obj;
}
