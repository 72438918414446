import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import { SelectAsDiv } from "./../../_components/Select";
import { studentActions, teacherActions } from "./../../_actions";
import { alertActions } from "./../../_actions/alerts";
import { teacherConstants } from "./../../_constants";
import $ from "jquery";

function PopUpAddFile(props) {
  let { onClickNo, width, param, messageErr } = props;

  const dispatch = useDispatch();

  const grades = useSelector((state) => state.grades);
  const [inputs, setInputs] = useState({
    file: "",
    title: "",
    grade_id: "",
    skill: "",
  });
  let { file, title, grade_id, skill } = inputs;

  function handleChangeFile(event) {
    dispatch(alertActions.error({ message: "", screen: "" }));
    setInputs((inputs) => ({
      ...inputs,
      file: event.target.files[0],
    }));
    const input = document.getElementById("files");
    input.style.setProperty("--after-content", '"' + 123 + '"');
  }

  useEffect(() => {
    dispatch(studentActions.getAllGrade());
  }, []);

  const [avalableSubmit, setAvalableSubmit] = useState(true);

  function handleSubmit() {
    if (validateParam() && avalableSubmit) {
      if (validateParam()) {
        setAvalableSubmit(false);
        var dataAddFile = new FormData();
        dataAddFile.append("title", title);
        dataAddFile.append("grade_id", grade_id);
        dataAddFile.append("skill", skill);
        dataAddFile.append("file", file);
        dispatch(teacherActions.addFileResource(dataAddFile, param));
        setTimeout(function () {
          setAvalableSubmit(true);
        }, 3000);
      }
    }
  }

  function validateParam() {
    return file && title?.trim() !== "" && grade_id && skill ? true : false;
  }

  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      <div
        className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
        style={{ width: width }}
      >
        <div className="form-gr">
          <span>Tệp</span>
          <div className="flex-align input_files">
            <input
              id="files"
              type="file"
              placeholder="Tên file"
              onChange={handleChangeFile}
              title={file?.name}
            />
            <label
              className="one-line"
              style={{
                fontSize: "1rem",
                color: "#8e8e8e",
                maxWidth: "60%",
              }}
              for="files"
              title={file?.name}
            >
              {file?.name || "Chưa chọn tệp"}
            </label>
          </div>
        </div>
        <div style={{ textAlign: "center", color: "#e22028" }}>
          {messageErr}
        </div>
        <div className="form-gr">
          <span>Tên tệp hướng dẫn</span>
          <input
            type="text"
            placeholder="Tên file"
            onChange={(e) => setInputs({ ...inputs, title: e.target.value })}
          />
        </div>
        <div className="form-gr">
          <span>Khối lớp</span>
          <SelectAsDiv
            keySelected={grade_id}
            textDefault={"Khối"}
            data={grades}
            onChangeSelect={(grade_id) => setInputs({ ...inputs, grade_id })}
            className="khoi"
            styleBox={{
              display: 'flex',
              justifyContent: 'center',
            }}
            styleItem={{
              justifyContent: 'center',
              textIndent: 'unset'
            }}
          />
        </div>
        <div className="form-gr">
          <span>Kỹ năng</span>
          <SelectAsDiv
            keySelected={skill}
            textDefault={"Kỹ năng"}
            data={teacherConstants.TEXT_LIST_SKILL}
            onChangeSelect={(skill) => setInputs({ ...inputs, skill })}
            className="ky-nang"
            styleBox={{
              display: 'flex',
              justifyContent: 'center',
            }}
            styleItem={{
              justifyContent: 'center',
              textIndent: 'unset'
            }}
          />
        </div>
        <div className="flex-m btn-popup-gr">
          <button className="btn-default wd-c mr-10" onClick={onClickNo}>
            Huỷ
          </button>
          <button
            className={
              "btn-bg-blue btn-default wd-c" +
              (!validateParam() ? " btn-disable" : "")
            }
            onClick={handleSubmit}
          >
            Tiếp
          </button>
        </div>
      </div>
    </div>
  );
}

export { PopUpAddFile };
