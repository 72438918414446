import { Link } from "react-router-dom";
import { HeaderCurve } from "../../_components/Header";
import { useState } from "react";
import { STEP_REGISTER } from "../../_constants/register";
import CreateAccount from "./CreateAccount";

export default function RegisterPage() {

  return (
    <div className="bg-login rel">
      <HeaderCurve></HeaderCurve>
      <div>
        <div className="sunE-form-container register-form-container">
        <div className="sunE-custom-form register-form-container_content responsive_small_screen_margin_login">
          <h2 className="title text-center">Đăng ký đại lý</h2>
          <div className="sunE-login-app">
            <CreateAccount />
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}