import React, { useState } from 'react';
import './style.css';
import { useSelector, useDispatch } from 'react-redux';
import { scheduleConstants, popupConstants } from './../../_constants';
import { scheduleActions } from './../../_actions';
import { Alert } from './../../_components/Alert';


function PopUpEditScheduleYear(props) {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    const schedules = useSelector(state => state.schedules.dataAddScheduleYear);
    const [inputs, setInputs] = useState({
        content: schedules.content
    });
    const [submitted, setSubmitted] = useState(false);
    let { width } = props;


    function close() {
        dispatch({
            'type': popupConstants.CLEAR_ALL_POPUP,
        })
    }

    function hanldeForm() {
        setSubmitted(true);
        if (validateParam()) {
            if (schedules.action === 'add') {
                dispatch(scheduleActions.addScheduleYear({
                    ...schedules,
                    content: inputs.content
                }));
            } else {
                dispatch(scheduleActions.editScheduleYear({
                    ...schedules,
                    content: inputs.content
                }));
            }
        }

    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function validateParam() {
        return (inputs.content.trim()) ? true : false;
    }

    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{width: width}}>
                {alert.message && alert.screen === scheduleConstants.SCREEN_ADD_SCHEDULE_YEAR && <Alert alert={alert} notShowComplete={true} />}
                <div className={'message-body' + (submitted && !inputs.content ? ' err' : '')}>
                    <textarea className="custom-area scrollbar-custom-2" name="content" id="message" rows="3" placeholder="Nhập nội dung kế hoạch của bạn" onChange={handleChange} defaultValue={schedules.content}></textarea>
                </div>
                <div className="flex-m btn-popup-gr">
                    <button className="btn-default btn-custom-width mr-10" onClick={close}>Huỷ</button>
                    <button  className={'btn-bg-blue btn-default btn-custom-width' + (!validateParam() ? ' btn-disable' : '')} onClick={hanldeForm}>Cập nhật</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpEditScheduleYear };