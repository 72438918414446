import { userConstants } from '../../_constants';
var initialState = {
    'data_setting': {
        'data': []
    },
    'app_info': {
        'data': []
    }
};

const setting = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.GET_SETTING_DETAIL:
            return {
                ...state,
                data_setting: {
                    data: action.data.user_setting,
                },
                app_info: {
                    data: action.data.app_info,
                }
            };
        default: return state;
    }
};

export { setting };