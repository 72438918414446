import { curriculumConstants } from "../../_constants";
import { userConstants } from "../../_constants";
var initialState = {
  data_map: {
    data_lesson: {
      unit_name: [],
    },
  },
  data_grid: {
    data: {
      lesson_data: {
        unit_name: [],
        data: [],
      },
    },
  },
  view: "map",
  lessons: {
    data: [],
  },
  loading: true,
};

const curriculumStudent = (state = initialState, action) => {
  switch (action.type) {
    case curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT_START:
      return {
        ...state,
        loading: true,
      };
    case curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT:
      return {
        ...state,
        loading: false,
        data_map: action.data_map,
      };
    case curriculumConstants.GET_DATA_GRID_CURRICULUM_STUDENT:
      return {
        ...state,
        data_grid: action.data_grid,
      };
    case curriculumConstants.CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case curriculumConstants.GET_LESSON_CURRICULUM_STUDENT:
      return {
        ...state,
        lessons: action.lessons,
      };
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { curriculumStudent };
