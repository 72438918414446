import { configConstants } from "../_constants/config";

const API = {
  baseURL: configConstants.AUTH_URL,
  get_packages: "api/package",
  get_packages_available: "api/package/available_package_list",
  get_packages_teacher: "api/package/package_teacher",
  payment_history: "api/web_advertise/history_payment",
  register_teacher: "api/teacher/register",
  verify_resgiter_teacher: "api/teacher/verify_auth",
  verify_register_teacher_by_zalo: "api/teacher/verify_auth",
  logout_account_service: `${configConstants.BASE_URL}login/logout?logout_app_id=VK7JG-NPHTM-C97JM-9MPGT-3V66T`,
  logout_account_exercise: `${configConstants.BASE_URL}account/logout?logout_app_id=VK7JG-NPHTM-C97JM-9MPGT-3V66T`,
  upgradeAccountByCode: "api/package/active_by_code",
  get_package_history: "api/payment/history_payment",
  post_package_active: "api/package/active",
};

export default API;
