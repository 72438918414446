 import React, { useState } from 'react';
function PopUpSelectObjectRollUp(props) {
    let { onClickNo, onClickYes, title } = props;
    const [inputs, setInputs] = useState({
        send_content: 'student'
    });


    function handleSubmit() {
        onClickYes(inputs.send_content);
        onClickNo();
    }

    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <h2 className="text-center popup-title bold title24">{title}</h2>
                <div className="radio-gr">
                    <input type="radio" onChange={() => setInputs({ ...inputs, send_content: 'student' })} defaultChecked id="student" name="send_content"/>
                    <label htmlFor="student">Gửi thông tin từng cá nhân</label>
                </div>
                <div className="radio-gr">
                    <input type="radio" onChange={() => setInputs({ ...inputs, send_content: 'class' })} id="class" name="send_content"/>
                    <label htmlFor="class">Gửi thông tin cả lớp</label>
                </div>
                <div className="flex-m form-sunE-button text-center">
                    <button className="btn-line-blue btn-create" onClick={handleSubmit}>Gửi</button>
                </div>
                <img src="/assets/images/icon/ico_close_popup.png" alt="ico_close_popup" className="close-popup-ab" onClick={onClickNo}/>
            </div>
        </div>

    );
}

export { PopUpSelectObjectRollUp };