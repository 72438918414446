import { userConstants } from "./../../_constants";
var initialState = {
  email: "",
  password: "",
  re_password: "",
  role: "",
  username: "",
  login_type: userConstants.LOGIN_TYPE_DEFAULT,
};

const register = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.ADD_DATA_REGISTER:
      return action.data;
    case userConstants.CHANGE_LOGIN_TYPE:
      return {
        ...state,
        login_type: userConstants.LOGIN_TYPE_SOCIAL,
        email: action.data.email,
      };
    case userConstants.RESET_LOGIN_TYPE:
      return {
        ...state,
        login_type: userConstants.LOGIN_TYPE_DEFAULT,
        email: "",
      };
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { register };
