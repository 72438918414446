import { useState } from "react";
import HeaderMain from "../../_components/Header/HeaderMain";
import './HomePage.style.scss'
import { homeType } from "../../_constants/home_type";
import { useSelector } from "react-redux";
import moment from "moment"

export default function HomePage() {
  const [typeCopy, setTypeCopy] = useState('')
  const [isCopied, setIsCopied] = useState({
    [homeType.LANDING]: false,
    [homeType.REGISTER]: false, 
  })
  const authentication = useSelector((state) => state.authentication);

  const handleCopy = (type, value) => {
    navigator.clipboard.writeText(value)
    if (!!isCopied?.[type]) {
      return;
    }
    setIsCopied(prev => ({
      ...prev,
      [type]: true
    }));
    setTimeout(() => {
      setIsCopied(prev => ({
        ...prev,
        [type]: false
      }));
    }, 3000)
  }

  const IconCopy = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="none"><path fill="#00B9B7" d="M8 7h12v12a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2z" opacity="0.16"/><path stroke="#00B9B7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 3H4v13"/><path stroke="#00B9B7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12v12a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2z"/></g></svg>
    )
  }

  const IconTick = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#00B9B7" d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59z"/></svg>
    )
  }

  const renderInfoAgencyItem = (text) => {
    return (
      <div className="info-agency-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
          <path 
            fill="#00B9B7" 
            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3m0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5m-1 5v5h-5v2h5v5h2v-5h5v-2h-5v-5z"
            />
        </svg>
        <span>{text}</span>
      </div>
    )
  }

  const renderBoxLink = (title,link, type) => {
    return (
      <div className="box-link box-info">
        <p className="title-box-link">{title}</p>
        <div className="d-flex">
          <div className="container-link-text"><a href={link} target="_blank" className="link-text">{link}</a></div>
          <button className="btn-copy-link" onMouseOver={() => setTypeCopy(type)} onMouseOut={() => setTypeCopy('')} onClick={() => handleCopy(type, link)}>
            {!!isCopied?.[type] ? <IconTick /> : <IconCopy />}
            {typeCopy === type && <span className="copy-text">{!!isCopied?.[type] ? 'Đã sao chép' : 'Sao chép'}</span>}
          </button>
        </div>
        <button className="btn-copy-link-mobile" onClick={() => handleCopy(type, link)}>
            {!!isCopied?.[type] ? <IconTick /> : <IconCopy />}
            {!!isCopied?.[type] ? 'Đã sao chép' : 'Sao chép'}
          </button>
      </div>
    )
  }

  return (
    <div className="bg-main rel">
      <HeaderMain />
      <div className="body-home-container">
        <div className="sunE-container">
          <div className="home-content">
            <div className="box-info-agency box-info">
              <div className="avatar-agency">
                <img
                  src={authentication?.gender === 'male' ? "/assets/images/avatar_male.png" : "/assets/images/avatar_female.png"}
                  alt="Logo Sun-E"
                />
              </div>
              <div className="main-info-agency">
                <p className="name-agency">
                  {authentication.fullname}
                </p>
                <div className="sub-info-agency">
                  {renderInfoAgencyItem(moment(authentication.birthday).format("DD/MM/YYYY"))}
                  {renderInfoAgencyItem(authentication.phone)}
                  {renderInfoAgencyItem(authentication.email)}
                </div>
              </div>
            </div>
            {renderBoxLink('Link Đăng ký tuyến dưới', authentication.downline_register, homeType.REGISTER)}
            {/* {renderBoxLink('Link Landing page cho khách hàng', authentication.promotional_link, homeType.LANDING)} */}
          </div>
        </div>
      </div>
    </div>
  )
}