import { teacherConstants, userConstants } from "../../_constants";
var initialState = {
  base_url: "",
  data: [],
  roll_up: [],
  exam_card: {
    data: [],
    list_score_percent: [],
    list_exam_type: [],
  },
  add_roll_up: {
    date: new Date(),
  },
  dataOffline: [],
  detail: {},
  students: {
    base_url: "",
    data: [],
  },
  requests: {
    list_request: [],
  },
  addStudent: {
    user_data: {
      avatar: "",
      email: "",
      fullname: "",
    },
  },
  exercises: {
    data_exercise: [],
    data_lessons_selected: [],
    students: [],
    histories: {
      data: [],
      detail: {
        data: {
          incomplete: [],
          completed: [],
          list_class_detail: [],
        },
      },
    },
    files: {
      param: {
        skill: [],
        level: [],
        grade_id: [],
      },
      data: [],
    },
  },
  originAttachFile: [],
  originTime: {
    startTime: new Date(),
    endTime: new Date(),
  },
  dateAddStudentExercise: {
    start_time: new Date(),
    end_time: new Date(),
  },
  statusAttachFile: {
    status: false,
  },
  dataServiceTeacher: {},
  edit_exercises: {},
  homeworks: {
    data: [],
    detail: {
      data: [],
      avg_score: "",
      min_score: "",
      max_score: "",
      exercise_name: "",
    },
    exercises: {
      exercise_data: {
        to_fullname: "",
        lesson_name: "",
        status: "",
      },
      resource_data: {
        type: "",
        path: "",
        content: "",
      },
      AI: {
        result_content: [],
      },
      edit_content: [],
    },
  },
  student_ranking: {
    data: [],
    base_url: "",
  },
  class_ranking: {
    data: [],
  },
  reports: {
    total_student: "",
    avg: 0,
    overview_score: {},
    overview_exercise: {},
    students: {
      student_data: {
        base_url: "",
        avatar: "",
      },
    },
    log_learning: {
      data: {
        recent_activity: [],
      },
    },
    learning_by_date: {
      data: [],
    },
  },
  criteria: [],
  setting_assign: [],
  data_assign_proposal: [],
};

const classes = (state = initialState, action) => {
  switch (action.type) {
    case teacherConstants.GET_LIST_CLASS:
      return {
        ...state,
        base_url: action.classes.base_url,
        data: action.classes.data,
      };
    case teacherConstants.GET_LIST_CLASS_OFFLINE:
      return {
        ...state,
        dataOffline: action.classes.data,
      };
    case teacherConstants.GET_DETAIL_CLASS:
      return {
        ...state,
        detail: action.class,
      };
    case teacherConstants.GET_DETAIL_CLASS_OFFLINE:
      return {
        ...state,
        detail: action.class?.class_info?.class_info,
        roll_up: action.class.data_roll_up,
      };
    case teacherConstants.GET_STUDENT_OFF_CLASS:
      return {
        ...state,
        students: action.students,
      };
    case teacherConstants.GET_STUDENT_REQUEST:
      return {
        ...state,
        requests: action.requests,
      };
    case teacherConstants.DELETE_STUDENT:
      return {
        ...state,
        students: {
          data: state.students.data.filter(
            (student) => !action.students.includes(student.id)
          ),
        },
      };
    case teacherConstants.REMOVE_REQUEST_STUDENT:
      return {
        ...state,
        requests: state.requests.filter(
          (request) => !action.list_request_id.includes(request.id)
        ),
      };
    case teacherConstants.ADD_STUDENT:
      return {
        ...state,
        addStudent: action.data,
      };
    case teacherConstants.ADD_DATA_EXERCISE:
      return {
        ...state,
        exercises: action.data,
      };
    case teacherConstants.RESET_DATA_EXERCISE:
      return {
        ...state,
        exercises: {
          ...initialState.exercises,
          data_exercise: [],
          data_lessons_selected: [],
        },
        edit_exercises: {},
      };
    case teacherConstants.GET_LIST_HISTORY_EXERCISE:
      return {
        ...state,
        edit_exercises: {},
        exercises: {
          ...state.exercises,
          students: [],
          histories: {
            ...state.exercises.histories,
            data: action.histories,
          },
        },
      };

    case teacherConstants.GET_DETAIL_HISTORY_EXERCISE:
      return {
        ...state,
        exercises: {
          ...state.exercises,
          histories: {
            ...state.exercises.histories,
            detail: action.histories,
          },
        },
      };
    case teacherConstants.ADD_DATA_FILE:
      return {
        ...state,
        exercises: {
          ...state.exercises,
          files: {
            ...state.files,
            data: action.data,
          },
        },
      };
    case teacherConstants.ADD_FILE_ATTACH_ORIGIN:
      return {
        ...state,
        originAttachFile: action.data,
      };
    case teacherConstants.ADD_TIME_TO_CHOOSE_FILE_SCREEN:
      return {
        ...state,
        originTime: action.data,
      };
    case teacherConstants.SAVE_DATE_ADD_STUDENT_EXERCISE:
      return {
        ...state,
        dateAddStudentExercise: action.data,
      };
    case teacherConstants.GET_LIST_HOMEWORK:
      return {
        ...state,
        homeworks: {
          ...state.homeworks,
          data: action.homeworks,
        },
      };
    case teacherConstants.GET_DETAIL_HOMEWORK:
      return {
        ...state,
        homeworks: {
          ...state.homeworks,
          detail: action.homeworks,
        },
      };
    case teacherConstants.GET_DETAIL_HOMEWORK_EXERCISE:
      return {
        ...state,
        homeworks: {
          ...state.homeworks,
          exercises: action.exercises,
        },
      };
    case teacherConstants.CALL_AI_HOMEWORK:
      return {
        ...state,
        homeworks: {
          ...state.homeworks,
          exercises: {
            ...state.homeworks.exercises,
            AI: action.data,
            edit_content: action.data.result_content,
          },
        },
      };
    case teacherConstants.GET_STUDENT_RANKING:
      return {
        ...state,
        student_ranking: {
          data: action.data,
          base_url: action.base_url,
        },
      };
    case teacherConstants.GET_CLASS_RANKING:
      return {
        ...state,
        class_ranking: {
          data: action.data,
        },
      };
    case teacherConstants.ADD_TIME_ADD_ROLL_UP:
      return {
        ...state,
        add_roll_up: {
          date: action.date,
        },
      };
    case teacherConstants.GET_EXAM_CARD:
      return {
        ...state,
        exam_card: action.exam_card,
      };
    case teacherConstants.GET_LIST_REPORT_CLASS:
      return {
        ...state,
        reports: {
          ...state.reports,
          base_url: action.reports.base_url,
          overview_exercise: action.reports.overview_exercise,
          overview_score: action.reports.overview_score,
          total_score: action.reports.total_score,
          total_student: action.reports.total_student,
          avg: action.reports.avg,
          total_exercise: action?.reports?.total_exercise,
        },
      };
    case teacherConstants.GET_REPORT_BY_STUDENT_ID:
      return {
        ...state,
        reports: {
          ...state.reports,
          students: action.students,
        },
      };
    case teacherConstants.GET_LOG_LEARNING_BY_STUDENT_ID:
      return {
        ...state,
        reports: {
          ...state.reports,
          log_learning: action.log_learning,
        },
      };
    case teacherConstants.GET_LIST_CRITERIA:
      return {
        ...state,
        criteria: action.criteria,
      };
    case teacherConstants.EDIT_EXERCISE:
      return {
        ...state,
        edit_exercises: action.exercises,
      };
    case teacherConstants.SOURCE_PAGE_ADD_EXCERCISE:
      return {
        ...state,
        sourcePageAddExcercise: action.sourcePageAddExcercise,
      };
    case teacherConstants.GET_LOG_LEARNING_BY_DATE:
      return {
        ...state,
        reports: {
          ...state.reports,
          learning_by_date: action.learnings,
        },
      };
    case teacherConstants.GET_DATA_SETTING_ASSIGN:
      return {
        ...state,
        setting_assign: action.setting,
      };
    case teacherConstants.GET_DATA_ASSIGN_SPECIFIC:
      return {
        ...state,
        data_assign_proposal: action.proposal,
      };
    case teacherConstants.CLEAR_DATA_EXERCISES:
      return {
        ...state,
        exercises: {
          data_exercise: [],
          data_lessons_selected: [],
          students: [],
          histories: {
            data: [],
            detail: {
              data: {
                incomplete: [],
                completed: [],
                list_class_detail: [],
              },
            },
          },
          files: {
            param: {
              skill: [],
              level: [],
              grade_id: [],
            },
            data: [],
          },
        },
        data_assign_proposal: [],
        setting_assign: [],
      };
    case teacherConstants.CLEAR_ONLY_DATA_EXERCISES:
      return {
        ...state,
        exercises: {
          ...state.exercises,
          data_exercise: [],
          data_lessons_selected: [],
        },
        data_assign_proposal: [],
        setting_assign: [],
      };
    case teacherConstants.ON_STATUS_ATTACH_FILE:
      return {
        ...state,
        statusAttachFile: true,
      };
    case teacherConstants.OFF_STATUS_ATTACH_FILE:
      return {
        ...state,
        statusAttachFile: false,
      };
    case teacherConstants.GET_SERVICE_TEACHER:
      return {
        ...state,
        dataServiceTeacher: action.data,
      };
    case teacherConstants.CLEAR_DATA_LESSONS:
      return {
        ...state,
        exercises: {
          ...state.exercises,
          data_exercise: [],
          data_lessons_selected: [],
          files: {
            param: {
              skill: [],
              level: [],
              grade_id: [],
            },
            data: [],
          },
        },
      };
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { classes };
