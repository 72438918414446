import { useEffect } from "react";

/**
 * useOutsideClick: hàm để bắt sự kiện outside click
 * @param ref dom cần check
 * @param onOutSideClick function handle
 */
const useOutsideClick = (ref, onOutSideClick) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutSideClick();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

export default useOutsideClick;
