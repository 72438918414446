import { Link } from "react-router-dom";
import './HeaderMain.style.scss'
import { useState } from "react";
import {history} from '../../../_helpers/history'
import { apiCaller } from "../../../_helpers";
export default function HeaderMain() {
  const [isHoverLogout, setIsHoverLogout] = useState(false)

  const handleLogout = async () => {
    await apiCaller('/logout', "POST", {})
    window.location.href = "/login";
    localStorage.removeItem("authentication");
    localStorage.removeItem("access_token");
  }

  return (
    <div className="header-main-container">
      <div className="sunE-container">
        <div className="header-main-content">
          <div className="logo">
            <Link to={`/`}>
              <img src={"/assets/images/logo.png"} alt={"Logo"} />
            </Link>
          </div>
          <div className="btn-logout" onMouseOver={() => setIsHoverLogout(true)} onMouseOut={() => setIsHoverLogout(false)} onClick={handleLogout}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke="#00B9B7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8m4-9l-4-4m4 4l-4 4m4-4H9"/></svg>
            {isHoverLogout && <span className="btn-logout-text">Đăng xuất</span>}
          </div>
        </div>
      </div>
    </div>
  );
}