import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

function LinkBack() {
    const history = useHistory();

    return (
        <Link to="/" onClick={() => history.go(-1)} style={{ fontSize: "18px" }}>
            Quay lại
        </Link>
    );
}

export { LinkBack };
