import React from 'react';
import { alertActions } from '../../_actions';
import { useDispatch } from 'react-redux';

function AlertSuccess(props) {
    const dispatch = useDispatch();

    // let autoClose = setTimeout(() => {
    //     close();
    // }, 2000);

    function close() {
        // clearTimeout(autoClose);
        props.onComplete();
        dispatch(alertActions.clear());

    }
    if (props.notShowComplete) {
        return null;
    } else {
        return (
            <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
                <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <p className="text-center" style={{whiteSpace: 'pre-line'}}>{props.message}</p>
                    <div className="form-sunE-button">
                        <button className="btn-line-blue" onClick={close}>Đóng</button>
                    </div>
                </div>
            </div>

        );
    }

}

export { AlertSuccess };