import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { teacherActions, studentActions } from './../../_actions';
import { useParams } from "react-router-dom";
import { configConstants, popupConstants } from './../../_constants';
import { userConstants } from '../../_constants';
import moment from 'moment'

import './style.css';

function PopUpAddStudent(props) {
    let { width } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const student = useSelector(state => state.classes.addStudent);
    const authentication = useSelector(state => state.authentication);

    function handleSubmit() {
        if(authentication.role === userConstants.ROLE_TEACHER){
            dispatch(teacherActions.addStudent({ class_id: id, user_code: student.user_data.user_code }));
        }else{
            dispatch(studentActions.addParent({user_code: student.user_data.user_code }));
        }
    }
    function close() {
        dispatch({
            type: popupConstants.CLEAR_ALL_POPUP,
        });
    }
    
    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ width: width }}>
                <p className="text-center popup-title bold">{student.avatar}</p>
                <div className="avt-pop">
                    <img src={student?.user_data?.avatar ? configConstants.BASE_URL + student?.user_data?.avatar : '/assets/images/hoso/default_avt.png'} alt={student?.user_data?.fullname} />
                </div>
                <h2 className="text-center popup-title bold title-student-pop cutom-mg">{student?.user_data?.full_name || student?.user_data?.fullname}</h2>
                {props?.isAddClass ? <p style={{padding: '16px', textAlign: 'center'}}>{`Email: ${student.user_data.email}`}</p> : <div className='info-container'>
                    <div className='info-row-box'>
                        <span className='label-info-left'>Email:{" "}</span>
                        <span>{student.user_data.email}</span>
                    </div>
                    <div className='info-row-box'>
                        <span className='label-info-left'>Ngày sinh:{" "}</span>
                        <span>{moment(new Date(student?.user_data?.birthday)).format('DD/MM/YYYY')}</span>
                    </div>
                </div>}
               
                <div className="flex-m btn-popup-gr ">
                    <button className="btn-default mr-10 width-100-percent" onClick={close}>Huỷ</button>
                    <button className="btn-bg-blue btn-default width-100-percent" onClick={handleSubmit}>{props?.isAddClass ? "Thêm vào lớp" : "Liên kết"}</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpAddStudent };