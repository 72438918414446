import { homeType } from "./../../_constants";
import { userConstants } from "./../../_constants";
import { isEmpty } from "lodash";

export function home_page_reducer(state = {}, action) {
  switch (action.type) {
    case homeType.SELECT_TEACHER_OR_CURRICULUM:
      return { ...state, itemActiveHomeStudent: action.payload };
    case userConstants.RESET_ALL_STATE:
      return {};
    default:
      return state;
  }
}
