import React from "react";
import "./style.css";

function PopUpZoomImage(props) {
  let { onClickNo, src, width } = props;
  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      <div
        className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical showImg"
        style={{ width: width }}
      >
        <div className="zoomImg center-flex">
          <img src={src} alt="zoom" />
        </div>
      </div>
      <img
        src="/assets/images/icon/ico_close_white_popup.png"
        alt="ico_close_popup"
        className="close-popup-ab contain_image"
        onClick={onClickNo}
        style={{
          height: "2.5rem",
          width: "2.5rem",
        }}
      />
    </div>
  );
}

export { PopUpZoomImage };
