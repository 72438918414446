import { studentConstants } from "../../_constants";
import { userConstants } from "../../_constants";
var initialState = [];

const grades = (state = initialState, action) => {
  switch (action.type) {
    case studentConstants.GET_ALL_GRADE:
      return action.grade;
    case studentConstants.LIST_CLASS_FILTER:
      return { ...state, listGrade: action.payload };
    case studentConstants.LIST_ADDRESS_FILTER:
      return { ...state, listAddress: action.payload };
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { grades };
