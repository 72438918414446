import { scheduleConstants } from "../../_constants";
var initialState = {
  moth: [],
  today: [],
  year: [],
  timeTable: {
    data: [],
    valid_to: false,
  },
  detail_today: {
    title: "",
    start_time: new Date(),
    end_time: new Date(),
    type: "personal",
    content: "",
    remind_time: 10,
    repeat_type: "no_repeat",
    checkRemind: true,
    action: "add",
    valid_to: new Date(),
  },
  delete_schedule: {
    showForm: false,
    id: "",
    updateAll: 0,
  },
  dataAddScheduleYear: {
    showForm: false,
  },
  selectDate: "",
  dateSelectedCalendar: "",
  status: {
    in_complete: [],
    complete: [],
  },
};

const schedules = (state = initialState, action) => {
  switch (action.type) {
    case scheduleConstants.GET_SCHEDULE_TODAY:
      return {
        ...state,
        today: action.today,
      };
    case scheduleConstants.GET_TIME_TABLE:
      return {
        ...state,
        timeTable: action.timeTable,
      };
    case scheduleConstants.ADD_DETAIL_SCHEDULE_DAY:
      return {
        ...state,
        detail_today: action.data,
      };
    case scheduleConstants.REMOVE_DETAIL_SCHEDULE_DAY:
      return {
        ...state,
        detail_today: initialState.detail_today,
      };
    case scheduleConstants.ADD_DELETE_SCHEDULE_DAY:
      return {
        ...state,
        delete_schedule: action.data,
      };
    case scheduleConstants.RESET_DELETE_SCHEDULE_DAY:
      return {
        ...state,
        delete_schedule: initialState.delete_schedule,
      };
    case scheduleConstants.GET_SCHEDULE_YEAR:
      return {
        ...state,
        year: action.year,
      };
    case scheduleConstants.ADD_DATA_SCHEDULE_YEAR:
      return {
        ...state,
        dataAddScheduleYear: action.dataAddScheduleYear,
      };
    case scheduleConstants.SET_SELECT_DATE:
      return {
        ...state,
        selectDate: action.time,
      };
    case scheduleConstants.SET_DATE_SELECTED_CALENDAR:
      return {
        ...state,
        dateSelectedCalendar: action.time,
      };
    case scheduleConstants.GET_STATUS:
      return {
        ...state,
        status: action.data,
      };
    default:
      return state;
  }
};

export { schedules };
