import React from 'react';
import { useDispatch } from 'react-redux';
import { scheduleActions } from './../../_actions';
import './style.css';

function PopUpShowTimeTable(props) {
    let { onClickClose, onClickEdit, onClickDelete, titleButtonDone, titleButtonCancel, width, dataEditTimeTable } = props;
    const dispatch = useDispatch();

    function deleteTimeTable() {
        onClickDelete();
        dispatch(scheduleActions.deleteTimeTable(dataEditTimeTable.id));
    }

    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ width: width }}>
                <div className="popup-body">
                    <div className="showtime-gr rel">
                        <span className="day">Thứ {dataEditTimeTable.day_number}</span>
                        <p className="bold">{dataEditTimeTable.start_time}- {dataEditTimeTable.end_time}</p>
                    </div>
                    <div className="input-gr ">
                        <input type="text" name="class" value={dataEditTimeTable.class_name} disabled className="text-center" />
                    </div>
                    {parseInt(dataEditTimeTable.remind) === 1 &&
                        <div className="input-gr datlichnhac">
                            <div className="datlichnhac-input-gr text-center">
                                <span>{parseInt(dataEditTimeTable.remind_time) === 0 ? "Nhắc đúng giờ" : "Nhắc trước " + dataEditTimeTable.remind_time + " phút"}</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="flex-m btn-popup-gr">
                    <button className=" btn-bg-blue btn-default btn-custom-width mr-10" onClick={deleteTimeTable}>{titleButtonCancel}</button>
                    <button className="btn-bg-blue btn-default btn-custom-width" onClick={onClickEdit}>{titleButtonDone}</button>
                </div>
                <img src="/assets/images/icon/ico_close_popup.png" alt="ico_close_popup" className="close-popup-ab" onClick={onClickClose} />
            </div>
        </div>

    );
}

export { PopUpShowTimeTable };